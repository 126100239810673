import React from 'react';
import Layout from 'layouts/Main';
import Home from './Home';

// async function action({ fetch }) {
async function action(context) {
  // const resp = await fetch('/graphql', {
  //   body: JSON.stringify({
  //     query: '{news{title,link,content}}',
  //   }),
  // });
  // const { data } = { news: []};
  // if (!data || !data.news) throw new Error('Failed to load the news feed.');
  if (context && context.params.progressionId) {
    // console.log('fetch progression here', context.params.progressionId);
  }
  return {
    routeName: 'home',
    title: 'Composer Studio',
    chunks: ['home'],
    params: context.params,
    component: (
      <Layout>
        <Home params={context.params} />
      </Layout>
    ),
  };
}

export default action;
